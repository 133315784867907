import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const CareerPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      totalExperience: 0,
      gender: "Male",
    },
  });

  const [positions, setPositions] = useState([]);
  const [profilePicUrl, setProfilePicUrl] = useState(""); // State for image URL
  const [resumeUrl, setResumeUrl] = useState(""); // State for resume URL
  const [selectedPosition, setSelectedPosition] = useState(""); // Track selected position
  const [selectedGender, setSelectedGender] = useState("Male"); // Track selected gender

  // Fetch positions from API
  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.post(
          // "http://api.srssoftwares.in/website/api/career/positions"
          "https://api.ptacerp.com/srswebsite/api/career/positions"
        );

        const result = [].concat(...response.data);
        console.log(result);
        // Map through positions and add default option at the beginning
        const positionsWithDefault = [
          // "Select",
          ...result.map((position) => position.PostName),
        ];
        console.log(positionsWithDefault[0]);
        setPositions(positionsWithDefault);
        // Set default position to the first element if available
        setSelectedPosition(positionsWithDefault[0]);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };
    fetchPositions();
  }, []);

  useEffect(() => {
    console.log(selectedPosition);
    if (selectedPosition) {
      clearErrors("position");
    }
  }, [selectedPosition, clearErrors]);

  const formConfig = {
    personalDetails: [
      {
        id: "position",
        label: "Position (Applying for)",
        type: "select",
        options: positions,
        // validation: { required: "Position is required" },
        validation: {
          required: false,
        },
      },
      {
        id: "name",
        label: "Name",
        type: "text",
        validation: { required: "Name is required" },
      },
      {
        id: "mobileNo",
        label: "Mobile No",
        type: "tel",
        validation: {
          required: "Mobile number is required",
          pattern: {
            value: /^\d{10}$/,
            message: "Mobile number must be 10 digits",
          },
        },
      },
      { id: "emailID", label: "Email ID", type: "email" },
      { id: "dob", label: "DOB", type: "date" },
      {
        id: "gender",
        label: "Gender",
        type: "select",
        options: ["Male", "Female", "Others"],
        validation: { required: false },
      },
      {
        id: "currentAddress",
        label: "Current Address",
        type: "text",
        validation: { required: "Current Address is required" },
      },
      { id: "permanentAddress", label: "Permanent Address", type: "text" },
      {
        id: "profilePicLink",
        label: "Passport Size Picture (JPG/PNG)",
        type: "file",
      },
      {
        id: "resumeLink",
        label: "Resume (PDF)",
        type: "file",
        validation: { required: "Resume is required" },
      },
      { id: "linkedInLink", label: "LinkedIn Link", type: "text" },
      {
        id: "expectedSalary",
        label: "Expected Salary (Monthly)",
        type: "number",
      },
    ],
    ifPreviousExperience: [
      { id: "companyName", label: "Company Name", type: "text" },
      { id: "designation", label: "Designation", type: "text" },
      { id: "totalExperience", label: "Total Experience ", type: "number" },
      { id: "reasonForLeaving", label: "Reason for Leaving", type: "text" },
      {
        id: "currentSalary",
        label: "Current Salary (Monthly)",
        type: "number",
      },
    ],
  };

  const onSubmit = async (data) => {
    if (!selectedPosition) {
      alert("Please select a position.");
      return;
    }

    const formData = new FormData();

    // Append form fields to formData
    Object.entries(data).forEach(([key, value]) => {
      if (key === "position") {
        formData.append(key, selectedPosition);
      } else {
        // Append other form fields to formData
        formData.append(key, value);
      }
    });

    if (data.resumeLink.length > 0) {
      const resumeFile = data.resumeLink[0];
      formData.append("resume", resumeFile);
      formData.append(
        "resumeLink",
        `https://api.ptacerp.com/srswebsite/uploads/resumes/${resumeFile.name}`
      );
    }

    if (data.profilePicLink.length > 0) {
      const profilePicFile = data.profilePicLink[0];
      formData.append("profilePic", profilePicFile);
      formData.append(
        "profilePicLink",
        `https://api.ptacerp.com/srswebsite/uploads/images/${profilePicFile.name}`
      );
    }

    // console.log(formData);
    try {
      await axios.post(
        "https://api.ptacerp.com/srswebsite/api/career/submit",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Form submitted successfully!");
      reset();
      setProfilePicUrl("");
      setSelectedPosition(positions[0] || ""); // Reset to default position
      setResumeUrl("");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit form. Please try again.", error);
    }
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const url =
        type === "image"
          ? `https://api.ptacerp.com/srswebsite/uploads/images/${file.name}`
          : `https://api.ptacerp.com/srswebsite/uploads/resumes/${file.name}`;
      if (type === "image") {
        setProfilePicUrl(url);
      } else {
        setResumeUrl(url);
      }

      clearErrors(type === "image" ? "profilePicLink" : "resumeLink");
    }
  };

  const renderField = (field) => {
    const commonProps = {
      ...register(field.id, field.validation),
      className:
        "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
    };

    switch (field.type) {
      case "select":
        if (field.id === "position") {
          return (
            <select
              id={field.id}
              {...commonProps}
              value={selectedPosition}
              onChange={(e) => {
                e.target.value !== "Select Position" &&
                  setSelectedPosition(e.target.value);
                clearErrors("position");
              }}
            >
              {field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
        }
        if (field.id === "gender") {
          return (
            <select
              id={field.id}
              {...commonProps}
              value={selectedGender}
              onChange={(e) => {
                setSelectedGender(e.target.value);
                clearErrors("gender");
              }}
            >
              {field.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
        }
        break;
      case "file":
        return (
          <>
            <input
              id={field.id}
              type='file'
              accept={
                field.id === "resumeLink"
                  ? "application/pdf"
                  : "image/jpeg,image/png"
              }
              className={commonProps.className}
              {...register(field.id, field.validation)}
              onChange={(e) =>
                handleFileChange(e, field.id === "resumeLink" ? "pdf" : "image")
              }
            />
          </>
        );
      default:
        return <input id={field.id} type={field.type} {...commonProps} />;
    }
  };

  return (
    <div className='container mx-auto flex gap-2 w-full'>
      <div className='grid gap-2 mb-2 w-full'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='bg-white p-2 rounded-lg shadow-lg'
        >
          <h2 className='text-xl font-bold mb-4 text-center'>Apply Now</h2>
          {Object.entries(formConfig).map(([sectionKey, fields]) => (
            <div key={sectionKey}>
              <h1 className='text-md font-semibold mb-2 bg-gray-200 p-2 rounded-lg'>
                {sectionKey.replace(/([A-Z])/g, " $1").toUpperCase()}
              </h1>
              <div className='grid grid-cols-2 gap-2'>
                {fields.map((field) => (
                  <div key={field.id} className='mb-4'>
                    <label
                      htmlFor={field.id}
                      className='block text-sm font-semibold text-gray-700'
                    >
                      {field.label}
                    </label>
                    {renderField(field)}
                    {errors[field.id] && (
                      <p className='text-red-600'>{errors[field.id].message}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className='flex justify-end'>
            <button
              type='submit'
              disabled={isSubmitting}
              className='bg-blue-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
            >
              {isSubmitting ? "Submitting..." : "Apply Now"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CareerPage;

// import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import axios from "axios";

// const CareerPage = () => {
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors, isSubmitting },
//     clearErrors,
//     watch,
//   } = useForm({
//     defaultValues: {
//       totalExperience: 0,
//       gender: "Male",
//     },
//   });

//   const [positions, setPositions] = useState([]);
//   const [profilePicUrl, setProfilePicUrl] = useState(""); // State for image URL
//   const [resumeUrl, setResumeUrl] = useState(""); // State for resume URL
//   const [selectedPosition, setSelectedPosition] = useState(""); // Track selected position
//   const [selectedGender, setSelectedGender] = useState("Male"); // Track selected gender

//   // Fetch positions from API
//   useEffect(() => {
//     const fetchPositions = async () => {
//       try {
//         const response = await axios.post(
//           // "http://api.srssoftwares.in/website/api/career/positions"
//           "https://api.ptacerp.com/srswebsite/api/career/positions"
//         );

//         const result = [].concat(...response.data);

//         // Map through positions and add default option at the beginning
//         const positionsWithDefault = [
//           "Select Position",
//           ...result.map((position) => position.PostName),
//         ];
//         setPositions(positionsWithDefault);
//       } catch (error) {
//         console.error("Error fetching positions:", error);
//       }
//     };
//     fetchPositions();
//   }, []);

//   useEffect(() => {
//     console.log(selectedPosition);
//     if (selectedPosition !== "Select Position") {
//       clearErrors("position");
//     }
//   }, [selectedPosition]);

//   const formConfig = {
//     personalDetails: [
//       {
//         id: "position",
//         label: "Position (Applying for)",
//         type: "select",
//         options: positions,
//         // validation: { required: "Position is required" },
//         validation: {
//           required: "Position is required",
//         },
//       },
//       {
//         id: "name",
//         label: "Name",
//         type: "text",
//         validation: { required: "Name is required" },
//       },
//       {
//         id: "mobileNo",
//         label: "Mobile No",
//         type: "tel",
//         validation: {
//           required: "Mobile number is required",
//           pattern: {
//             value: /^\d{10}$/,
//             message: "Mobile number must be 10 digits",
//           },
//         },
//       },
//       { id: "emailID", label: "Email ID", type: "email" },
//       { id: "dob", label: "DOB", type: "date" },
//       {
//         id: "gender",
//         label: "Gender",
//         type: "select",
//         options: ["Male", "Female", "Others"],
//         validation: { required: "Gender is required" },
//       },
//       {
//         id: "currentAddress",
//         label: "Current Address",
//         type: "text",
//         validation: { required: "Current Address is required" },
//       },
//       { id: "permanentAddress", label: "Permanent Address", type: "text" },
//       {
//         id: "profilePicLink",
//         label: "Passport Size Picture (JPG/PNG)",
//         type: "file",
//       },
//       {
//         id: "resumeLink",
//         label: "Resume (PDF)",
//         type: "file",
//         validation: { required: "Resume is required" },
//       },
//       { id: "linkedInLink", label: "LinkedIn Link", type: "text" },
//       {
//         id: "expectedSalary",
//         label: "Expected Salary (Monthly)",
//         type: "number",
//       },
//     ],
//     ifPreviousExperience: [
//       { id: "companyName", label: "Company Name", type: "text" },
//       { id: "designation", label: "Designation", type: "text" },
//       { id: "totalExperience", label: "Total Experience ", type: "number" },
//       { id: "reasonForLeaving", label: "Reason for Leaving", type: "text" },
//       {
//         id: "currentSalary",
//         label: "Current Salary (Monthly)",
//         type: "number",
//       },
//     ],
//   };

//   const onSubmit = async (data) => {
//     const formData = new FormData();

//     // Append form fields to formData
//     Object.entries(data).forEach(([key, value]) => {
//       formData.append(key, value);
//     });

//     if (data.resumeLink.length > 0) {
//       const resumeFile = data.resumeLink[0];
//       formData.append("resume", resumeFile);
//       formData.append(
//         "resumeLink",
//         // `http://api.srssoftwares.in/uploads/resumes/${resumeFile.name}`
//         `https://api.ptacerp.com/srswebsite/uploads/resumes/${resumeFile.name}`
//       );
//     }

//     if (data.profilePicLink.length > 0) {
//       const profilePicFile = data.profilePicLink[0];
//       formData.append("profilePic", profilePicFile);
//       formData.append(
//         "profilePicLink",
//         // `http://api.srssoftwares.in/uploads/images/${profilePicFile.name}`
//         `https://api.ptacerp.com/srswebsite/uploads/images/${profilePicFile.name}`
//       );
//     }
//     console.log(formData);
//     try {
//       await axios.post(
//         // "http://api.srssoftwares.in/website/api/career/submit",
//         "https://api.ptacerp.com/srswebsite/api/career/submit",
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       alert("Form submitted successfully!");
//       reset();
//       setProfilePicUrl("");
//       setSelectedPosition("Select position");
//       setResumeUrl("");
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       alert("Failed to submit form. Please try again.", error);
//     }
//   };

//   const handleFileChange = (event, type) => {
//     const file = event.target.files[0];
//     if (file) {
//       const url =
//         type === "image"
//           ? `https://api.ptacerp.com/srswebsite/uploads/images/${file.name}`
//           : `https://api.ptacerp.com/srswebsite/uploads/resumes/${file.name}`;
//       if (type === "image") {
//         setProfilePicUrl(url);
//       } else {
//         setResumeUrl(url);
//       }

//       clearErrors(type === "image" ? "profilePicLink" : "resumeLink");
//     }
//   };

//   const renderField = (field) => {
//     const commonProps = {
//       ...register(field.id, field.validation),
//       className:
//         "mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
//     };

//     switch (field.type) {
//       case "select":
//         if (field.id === "position") {
//           return (
//             <select
//               id={field.id}
//               {...commonProps}
//               value={selectedPosition}
//               onChange={(e) => {
//                 e.target.value !== "Select Position" &&
//                   setSelectedPosition(e.target.value);
//                 clearErrors("position");
//               }}
//             >
//               {field.options.map((option, index) => (
//                 <option key={index} value={option}>
//                   {option}
//                 </option>
//               ))}
//             </select>
//           );
//         }
//         if (field.id === "gender") {
//           return (
//             <select
//               id={field.id}
//               {...commonProps}
//               value={selectedGender}
//               onChange={(e) => {
//                 setSelectedGender(e.target.value);
//                 clearErrors("gender");
//               }}
//             >
//               {field.options.map((option, index) => (
//                 <option key={index} value={option}>
//                   {option}
//                 </option>
//               ))}
//             </select>
//           );
//         }
//         break;
//       case "file":
//         return (
//           <>
//             <input
//               id={field.id}
//               type='file'
//               accept={
//                 field.id === "resumeLink"
//                   ? "application/pdf"
//                   : "image/jpeg,image/png"
//               }
//               className={commonProps.className}
//               {...register(field.id, field.validation)}
//               onChange={(e) =>
//                 handleFileChange(e, field.id === "resumeLink" ? "pdf" : "image")
//               }
//             />
//             {field.id === "profilePicLink" && profilePicUrl && (
//               <input
//                 className={commonProps.className}
//                 value={profilePicUrl}
//                 readOnly
//               />
//             )}
//             {field.id === "resumeLink" && resumeUrl && (
//               <input
//                 className={commonProps.className}
//                 value={resumeUrl}
//                 readOnly
//               />
//             )}
//           </>
//         );
//       default:
//         return <input id={field.id} type={field.type} {...commonProps} />;
//     }
//   };

//   return (
//     <div className='container mx-auto flex gap-2 w-full'>
//       <div className='grid gap-2 mb-2 w-full'>
//         <form
//           onSubmit={handleSubmit(onSubmit)}
//           className='bg-white p-2 rounded-lg shadow-lg'
//         >
//           <h2 className='text-xl font-bold mb-4 text-center'>Apply Now</h2>
//           {Object.entries(formConfig).map(([sectionKey, fields]) => (
//             <div key={sectionKey}>
//               <h1 className='text-md font-semibold mb-2 bg-gray-200 p-2 rounded-lg'>
//                 {sectionKey.replace(/([A-Z])/g, " $1").toUpperCase()}
//               </h1>
//               <div className='grid grid-cols-2 gap-2'>
//                 {fields.map((field) => (
//                   <div key={field.id} className='mb-4'>
//                     <label
//                       htmlFor={field.id}
//                       className='block text-sm font-semibold text-gray-700'
//                     >
//                       {field.label}
//                     </label>
//                     {renderField(field)}
//                     {errors[field.id] && (
//                       <p className='text-red-600'>{errors[field.id].message}</p>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}

//           <div className='flex justify-end'>
//             <button
//               type='submit'
//               disabled={isSubmitting}
//               className='bg-blue-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
//             >
//               {isSubmitting ? "Submitting..." : "Apply Now"}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default CareerPage;
